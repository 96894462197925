import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  loading() {
    if (this.form.reportValidity()) document.body.classList.add("loading")
  }

  disconnect() {
    document.body.classList.remove("loading")
  }

  get form() {
    return this.element.closest("form")
  }
}
