import { Controller } from '@hotwired/stimulus'
import Dashboard from '@uppy/dashboard'
import FileInput from '@uppy/file-input'
import { nanoid } from 'nanoid'
import { uppyInstance, uploadedFileData } from '../uppy/index'
import Japanese from '@uppy/locales/lib/ja_JP'

export default class extends Controller {
  static targets = [ 'input' ]
  static values = { types: String, server: String }

  connect() {
    this.uppy = this.createUppy()
  }

  disconnect() {
    this.uppy.close()
  }

  createUppy() {
    const uppy = uppyInstance({
      id: this.inputTarget.id,
      types: JSON.parse(this.typesValue),
      server: this.serverValue,
    })
    .use(Dashboard, {
      target: this.inputTarget.parentNode,
      inline: true,
      height: 170,
      locale: Japanese,
    })

    uppy.on('upload-success', (file, response) => {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = `${this.inputTarget.name}`
      hiddenField.value = uploadedFileData(file, response, this.serverValue)

      this.element.appendChild(hiddenField)
    })

    return uppy
  }
}
