import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["file"]

  submit() {
    this._disableUnchangedFiles()
  }

  _disableUnchangedFiles() {
    this.fileTargets.map(file => { if (!file.files.length) file.disabled = true })
  }
}
